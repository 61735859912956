import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './CartPage.module.scss';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import GoodsCard from '../../components/mui/goodsCard/GoodsCard';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import OrangeButton from '../../components/mui/orangeButton/OrangeButton';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { useDispatch } from 'react-redux';
import { updateCart } from '../../redux/actions';

const CartPage = ({resetPage}) => {
  const location = useLocation();
  const [cart, setCart] = useState([]);
  const [isFetchNeeded, setIsFetchNeeded] = useState(false);
  const dispatch = useDispatch();

  const linksData = [
    { path: "/", label: "Home" },
    { path: "/pricelist", label: "Pricelist" }
  ];

  const fetchCartData = () => {
    getRequest(`/cart`)
    .then((response) => {
      const responseData = response.data;
      setCart(responseData);
    })
    .catch((error) => console.error('Error fetching cart:', error));
  }

  const postCartData = (id, count) => {
    const cartRequest = {
      goodsId: id,
      count: count,
    };

    postCsrf(`/cart`, JSON.stringify(cartRequest))
      .then((response) => {
        const responseData = response.data;
        dispatch(updateCart());
        if (count === 0) {
          fetchCartData();
        }
      })
      .catch((error) => console.error('Error updating cart:', error));
  }

  const placeOrder = () => {
    postCsrf(`/cart/order/place`)
      .then((response) => {
        fetchCartData();
        dispatch(updateCart());
      })
      .catch((error) => console.error('Error placing order:', error));
  }

  useEffect(() => {
    fetchCartData();
  }, [location.search]);

  return (
    <Container className={classnames(styles.pageContainer)}>
      <Box my={'10px'} display='flex' alignItems={'center'} >
          <div className={classnames('mb-2')}>
              <DynamicBreadcrumbs links={linksData} />
          </div>
      </Box>

      <Typography component='h3' className={classnames(styles.paragraphHeader)}>
        Your order
      </Typography>
      <Typography component='p' className={classnames(styles.paragraph)}>
        The price and composition of your order may change if an item is out of stock
      </Typography>
      
      <div className={classnames(styles.goodsListContainer)}>
          {cart.map((goods, index) => (
              <GoodsCard
                key={goods.id}
                id={goods.id}
                title={goods.name}
                img={goods.image}
                quantityAvailable={goods.maxCount}
                quantityToBuy={goods.count}
                isHot={goods.isHot}
                showDelete={true}
                onCountChange={(id, count) => {postCartData(id, count)}}
              />
          ))}
      </div>

      <Box className={classnames(styles.placeOrderContainer)} >
        <Box className={classnames(styles.placeOrderText)}>
          <Typography component='span' fontSize='18px'>
            Cost of order
          </Typography>
          <Typography component='p' fontSize='12px' fontWeight='400 !important'>
            Full cost of the order will be announced to you by our manager
          </Typography>
        </Box>
        <Box className={classnames(styles.palceOrderButton)}> 
          <OrangeButton
              isDisabled={!(cart.length > 0)}
              title={"place order"} 
              onClick={placeOrder}
              />
        </Box>
      </Box>


    </Container>
  );
};

export default CartPage;