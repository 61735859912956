// cartReducer.js
import { UPDATE_HEADER, RESET_UPDATE_HEADER, RESET_UPDATE_PAGE, FETCH_CART } from './actions';

const initialState = {
  shouldUpdateHeader: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HEADER:
      return {
        ...state,
        shouldUpdateHeader: action.payload
      };
    case RESET_UPDATE_HEADER:
      return {
        ...state,
        shouldUpdateHeader: false
      };
    case FETCH_CART:
      return {
        ...state,
        shouldUpdatePage: action.payload
      };
    case RESET_UPDATE_PAGE:
      return {
        ...state,
        shouldUpdatePage: false
      };
    default:
      return state;
  }
};

export default cartReducer;