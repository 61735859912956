import axios from 'axios';
import { Cookies } from "react-cookie-consent";

export const getAuthToken = () => {
    return Cookies.get('auth_token');
};

export const setAuthHeader = (token) => {
    if (Cookies.get('cookie-consent') === "accepted") {
        Cookies.set('auth_token', token, { expires: 365 })
    } else {
        Cookies.set('auth_token', token)
    }
};

export const setUsername = (username) => {
    if (Cookies.get('cookie-consent') === "accepted") {
        Cookies.set('username', username, { expires: 365 })
    } else {
        Cookies.set('username', username)
    }
};

export const getUsername = () => {
    return Cookies.get('username');
};

export const logout = () => {
    Cookies.remove('auth_token')
    Cookies.remove('username');
};

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.withCredentials = true

export const postCsrf = async (path, data) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.post(path, data, config);
}

export const loginCsrf = async (path, data) => {
    const csrfToken = await fetchCsrfToken();
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    config = {
        headers : {'X-Xsrf-Token': csrfToken}
    };
    return axios.post(path, data, config);
}

async function fetchCsrfToken() {
    const response = await axios.get('/v1/csrf-token');
    return response.data.csrfToken;
}

export const postCsrfForm = async (path, data) => {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.post(path, data, config);
}

export const deleteCsrf = async (path) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.delete(path, config);
}

export const getRequest = (path) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    
    return axios.get(path, config);
}

export const downloadRequest = (path) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.responseType = 'blob'
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    
    return axios.get(path, config);
}