// Footer.js
import React from 'react';
import { Box, Grid } from '@mui/material';
import PuffOptLogo from '../../assets/images/PuffOptLogo.svg'
import { Margin } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box sx={{background: "#202020", display: "flex", justifyContent: "center", paddingTop: "10px", paddingBottom: "10px"}}>
      <img src={PuffOptLogo} alt="Puff Opt Logo" height="30" />
    </Box>
  );
};

export default Footer;
