import React, { useEffect } from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import styles from './HomePage.module.scss';
import classnames from 'classnames';
import OrangeButton from '../../components/mui/orangeButton/OrangeButton';
import VapeCategory from '../../assets/images/VapeCategory.png'
import LiquidsCategory from '../../assets/images/LiquidsCategory.png'
import SnusCategory from '../../assets/images/SnusCategory.png'
import TechnicsCategory from '../../assets/images/TechnicsCategory.png'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const categories = [
  { name: 'Vape', img: VapeCategory },
  { name: 'Liquids', img: LiquidsCategory },
  { name: 'Snus', img: SnusCategory },
  { name: 'Technics', img: TechnicsCategory },
];

const HomePage = () => {
  const location = useLocation();

  return (
    <Container className={classnames(styles.pageContainer)}>
      <div className={classnames(styles.yellowCircle, styles.circleOne)}></div>
      <div className={classnames(styles.yellowCircle, styles.circleTwo)}></div>
      <Box zIndex={"100"} position={"relative"} textAlign={"center"} marginTop={"1rem"} marginBottom={"2.5rem"}>
        <Typography color="#F3F3F3" variant="h2" component="h1" gutterBottom>
          WHOLESALE OF VAPES, LIQUIDS AND SNUS
        </Typography>
        <Typography color="#F3F3F3" variant="h6" paddingX={"15%"} paragraph>
          Yes, yes, that's all of us. To see our products go to the catalogue and choose the products you need.
        </Typography>
        <Link to={`/category?category=Vape&model`} className={classnames(styles.catalogButton)} style={{textDecoration: 'none'}}>
            <OrangeButton title={"catalog"} />
        </Link>
      </Box>


      <Container className={classnames(styles.categoryTilesBlock)} >

        <Typography variant="h4" align='center' textTransform={"uppercase"} fontWeight={"700"} paddingTop={"10px"}>Select a categroy</Typography>
        
        <Grid container justifyContent="center" >
          {categories.map((category, index) => (
            <Grid item xs={6} sm={6} md={6} key={index}>
              <Link to={`/category?category=${category.name}`} style={{textDecoration: 'none'}}>
                <Card className={classnames(styles.categoryTile)}>
                  <CardActionArea sx={{height: "inherit"}}>
                    
                    <Box position={"absolute"} right={"1%"}>
                      <ArrowOutwardIcon sx={{color: 'white'}}/>
                    </Box>
                    
                    <CardMedia
                      component="img"
                      height="100%"
                      image={category.img}
                      alt={category.name}
                    />

                    <CardContent sx={{padding: 0, paddingBottom: "8px", position: "absolute", bottom: '0%', width: "100%", justifyContent: "center", display: "flex" }}>
                      <Typography variant="h6" fontWeight={"600"} color="#F3F3F3">
                        {category.name}
                      </Typography>
                    </CardContent>

                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
      

      <Box textAlign={"center"} marginTop={"1rem"} marginBottom={"2.5rem"}>
        <Typography textTransform={"uppercase"} color="#F3F3F3" variant="h2" component="h1" gutterBottom>
          We have the best prices
        </Typography>
        <Typography color="#F3F3F3" variant="h6" paddingX={"15%"} paragraph >
          Go to our price page and see for yourself.
        </Typography>
        <Link to={`/category?category=Vape&model`} className={classnames(styles.catalogButton)} style={{textDecoration: 'none'}}>
            <OrangeButton title={"catalog"} />
        </Link>
      </Box>
    </Container>
  );
};

export default HomePage;
