import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './GoodsCard.module.scss';
import classnames from 'classnames';
import FireIcon from '../../../assets/images/FireIcon.svg'
import RemoveGoodFromCart from '../../../assets/images/RemoveGoodFromCart.svg'
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function GoodsCard({ id, title, img, quantityAvailable, quantityToBuy, isHot, showDelete, onCountChange = (id, count) => {} }) {
    const [currentQuantity, setCurrentQuantity] = useState(quantityToBuy);
    const [isEditing, setIsEditing] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleIncrease = () => {
        let quantity = 0;
        if (currentQuantity === 1) {
            quantity = 5;
        } else if (currentQuantity + 5 < quantityAvailable) {
            quantity = Number(currentQuantity) + 5;
        } else {
            quantity = quantityAvailable;
        }

        setCurrentQuantity(quantity);
        onCountChange(id, quantity);
    };

    const handleDecrease = () => {
        let quantity = 0;
        if (currentQuantity > 0) {
            if (showDelete && currentQuantity <= 5) {
                quantity = 1;
            } else {
                const roundedQuantity = Math.round(currentQuantity / 5) * 5;
                quantity = currentQuantity % 5 === 0 ? currentQuantity - 5 : roundedQuantity;
            }

            setCurrentQuantity(quantity);
            onCountChange(id, quantity);
        }
    };

    const handleDelete = async () => {
        setCurrentQuantity(0);
        onCountChange(id, 0);
    };

    const handleImageClick = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value === '' || /^\d+$/.test(value)) {
            setCurrentQuantity(Number(value));
        }
    };

    const handleInputBlur = () => {
        setIsEditing(false);
        if (currentQuantity > quantityAvailable) {
            setCurrentQuantity(quantityAvailable);
        } else if (currentQuantity < 0) {
            setCurrentQuantity(0);
        }
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleInputBlur();
        }
    };

    return (
        <div className={classnames(styles.cardContainer)}>
            <Card className={classnames(styles.goodsCard)}>
                <div className={classnames(styles.cardInner)}>
                    <div className={classnames(styles.productImage)} onClick={handleImageClick}>
                        {isHot ? (
                            <div className={classnames(styles.fireIcon)}>
                                <img src={FireIcon} alt="Hot Item" />
                            </div> 
                        ) : (null)}
                        <CardMedia
                            component="img"
                            alt="Product Image"
                            image={img}
                            title={title}
                            loading="lazy"
                        />
                    </div>
                    <CardContent className={classnames(styles.cardContent)}>
                        <Typography className={classnames(styles.productTitle)}>
                            {title}
                        </Typography>
                        <Typography fontSize={'12px'} color={'gray'} display={'inline-block'}>
                            Quantity available:
                        </Typography>
                        <Typography fontSize={'14px'} color='#F3F3F3' display={'inline-block'} marginLeft='3px' className={classnames(styles.productQuantity)}>
                            {quantityAvailable} pcs
                        </Typography>
                    </CardContent>
                    {showDelete ? (
                        <div className={classnames(styles.deleteButton)} onClick={handleDelete}>
                            <img src={RemoveGoodFromCart} alt="Delete button" />
                        </div> 
                    ) : (null)}
                    <div className={classnames(styles.quantityToBuySection)}>
                        <IconButton aria-label="remove" className={classnames(styles.removeButton)} onClick={handleDecrease}>
                            <RemoveIcon sx={{color: 'white', width: '18px', height: '18px'}}/>
                        </IconButton>
                        {isEditing ? (
                            <TextField
                                variant="standard"
                                value={currentQuantity}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                onKeyDown={handleInputKeyDown}
                                autoFocus
                                inputProps={{ style: { textAlign: 'center', color: 'white', fontSize: '18px', maxWidth: '50px' } }}
                            />
                        ) : (
                            <Typography variant="h6" className={classnames(styles.productQuantityToBuy)} onClick={handleEditClick}>
                                {currentQuantity}
                            </Typography>
                        )}
                        <IconButton aria-label="add" className={classnames(styles.addButton)} onClick={handleIncrease}>
                            <AddIcon sx={{color: 'white'}}/>
                        </IconButton>
                    </div>
                </div>
            </Card>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogContent>
                    <img src={img} alt="Product Image" style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default GoodsCard;
