import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './CategoryPage.module.scss';
import classnames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import GoodsCard from '../../components/mui/goodsCard/GoodsCard';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import Filter from '../../components/mui/filter/Filter';
import { updateCart } from '../../redux/actions';
import { useDispatch } from 'react-redux';

const CategoryPage = () => {
  const location = useLocation();
  const [cartData, setCartData] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const [hotModels, setHotModels] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getRequest(`/cart`)
      .then((response) => {
        const responseData = response.data;
        setCartData(responseData);
        getGoods();
        getHotModels();
      })
      .catch((error) => console.error('Error fetching goods:', error));
  }, [location.search]);

  let previousModel = null;


  const getHotModels = () => {
    const searchParams = new URLSearchParams(location.search);
    getRequest(`/price/hot?category=${searchParams.get("category")}`)
      .then((response) => {
        const responseData = response.data;
        setHotModels(responseData);
      })
      .catch((error) => console.error('Error fetching hot models:', error));
  }

  const getGoods = () => {
    const searchParams = new URLSearchParams(location.search);
    getRequest(`/goods?category=${searchParams.get("category")}&models=${searchParams.get("models") ? searchParams.get("models") : ""}`)
      .then((response) => {
        const responseData = response.data;
        setGoodsList(responseData);
      })
      .catch((error) => console.error('Error fetching goods:', error));
  }

  const getGoodsCount = (id) => {
    let good = cartData.find((good) => good.id === id);
    return good ? good.count : 0;
  }

  const getIsGoodHot = (model) => {
    return hotModels.includes(model);
  }
  
  const postCartData = (id, count) => {
    const cartRequest = {
      goodsId: id,
      count: count,
    };

    postCsrf(`/cart`, JSON.stringify(cartRequest))
      .then((response) => {
        const responseData = response.data;
        dispatch(updateCart());
      })
      .catch((error) => console.error('Error updating cart:', error));
  }

  return (
    <Container className={classnames(styles.pageContainer)}>
      <Filter />
    
      <div className={classnames(styles.goodsListContainer)}>
        {goodsList.map((goods, index) => {
          const showModelHeader = goods.model !== previousModel;
          previousModel = goods.model;
          return (
            <Box key={goods.id} width={'100%'}>
              {showModelHeader && <Typography component={'h2'} className={classnames(styles.modelText)}>{goods.model}</Typography>}
              <GoodsCard
                id={goods.id}
                title={goods.name}
                img={goods.image}
                quantityAvailable={goods.count}
                quantityToBuy={getGoodsCount(goods.id)}
                isHot={getIsGoodHot(goods.model)}
                onCountChange={(id, count) => {postCartData(id, count)}}
              />
            </Box>
          );
        })}
      </div>
    </Container>
  );
};

export default CategoryPage;