import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './JobsPage.module.scss';
import classnames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';

const JobsPage = () => {
  const location = useLocation();
  const [isFetchOk, setIsFetchOk] = useState("Data updating...");

  useEffect(() => {
    getRequest(`/jobs/trigger/all`)
      .then((response) => {
        setIsFetchOk("Data updated from google sheets");
      })
      .catch((error) => {
        setIsFetchOk("Something went wrong, data was not updated");
      });
  }, [location.search]);


  return (
    <Container className={classnames(styles.pageContainer, styles.jobsText)}>
      {isFetchOk}
    </Container>
  );
};

export default JobsPage;