import React from 'react';
import Button from '@mui/material/Button';
import styles from './OrangeButton.module.scss'
import classnames from 'classnames';

function OrangeButton({ key, title, onClick = () => {}, type = 'button', isDisabled = false}) {

    return (
        <Button    
                className={classnames(styles.orangeButton)}
                variant="contained"
                onClick={() => onClick()}
                type={type}
                disabled={isDisabled}
            >
                {title}
            </Button>     
    );
}

export default OrangeButton;
