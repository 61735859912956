import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ScrollToTop from './components/scroll/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

if (process.env.REACT_APP_IS_PRODUCTION === "true") {
  const style = document.createElement("style");
  style.innerHTML = "body > iframe { display: none; }";
  document.head.appendChild(style);
}

root.render(
  process.env.REACT_APP_IS_PRODUCTION === "true" ? (
      <BrowserRouter>
        <ScrollToTop/>
          <App />
   
      </BrowserRouter>
  ) : (
    <React.StrictMode>
      <BrowserRouter>
       <ScrollToTop/>
        <App />
     
      </BrowserRouter>
    </React.StrictMode>
  )
);

reportWebVitals();
