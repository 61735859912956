import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './layouts/authorization/login/LoginPage';
import Header from './components/header/Header';
import DynamicComponent from './components/dynamicHeader/DynamicComponent';
import { getAuthToken, getRequest } from './services/fetch/AxiosHelper';
import HomePage from './layouts/homepage/HomePage';
import Footer from './components/footer/Footer';
import CategoryPage from './layouts/category/CategoryPage';
import PricelistPage from './layouts/pricelist/PricelistPage';
import CartPage from './layouts/cart/CartPage';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import JobsPage from './layouts/jobs/JobsPage.js';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <Provider store={store}>
      <DynamicComponent>
        <Header />
      </DynamicComponent>
      <div className="page-container">
          <Routes>
            <Route path="/"  element={<HomePage />} />
            <Route path="/category" element={<CategoryPage/>} />
            <Route path="/pricelist"  element={<PricelistPage />} />
            <Route path="/cart"  element={<CartPage />} />
            <Route path="/jobs"  element={<JobsPage />} />
          </Routes>
      </div>
      <Footer/>
      </Provider>
    </div>
  );
};

export default App;