import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import styles from './PriceCard.module.scss';
import classnames from 'classnames';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


function PriceCard({ title, characteristic, img, priceTable }) {
    return (
        <div className={classnames(styles.cardContainer)}>
            <Card className={classnames(styles.priceCard)}>
                <div className={classnames(styles.cardInner)}>
                    <div className={classnames(styles.productImage)}>
                        <CardMedia
                            component="img"
                            alt="Product Image"
                            image={img}
                            title={title}
                            loading="lazy"
                        />
                        <Typography className={classnames(styles.productTitle)}>
                            {title}
                        </Typography>
                        {characteristic ? (
                            <Typography className={classnames(styles.productPuffCount)}>
                                {characteristic}
                            </Typography>
                        ) : (null)}
                    </div>
                    <CardContent className={classnames(styles.cardContent)}>
                        <TableContainer component={Paper} className={classnames(styles.tableContainer)}>
                            <Table sx={{ minWidth: '150px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classnames(styles.tableHeader)}>Quantity</TableCell>
                                        <TableCell className={classnames(styles.tableHeader)}>Euro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {priceTable.map((priceRow, index) => (
                                        <TableRow key={index} className={classnames(styles.priceRow)} >
                                            <TableCell className={classnames(styles.tableCell)}>{priceRow.quantity}</TableCell>
                                            <TableCell className={classnames(styles.tableCell)}>{priceRow.euro}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </div>
            </Card>
        </div>
    );
}

export default PriceCard;
