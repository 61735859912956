import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicComponent = ({children}) => {
 
const location = useLocation()

const [showNavBar, setShowNavBar] = useState(false)

useEffect(() => {
  setShowNavBar(location.pathname !== '/auth/login') 
}, [location])

return (
    <div>
      {showNavBar && children}
    </div>
  );
};


export default DynamicComponent;