export const UPDATE_HEADER = 'UPDATE_HEADER';
export const RESET_UPDATE_HEADER = 'RESET_UPDATE_HEADER';
export const FETCH_CART = 'FETCH_CART';
export const RESET_UPDATE_PAGE = 'RESET_UPDATE_PAGE';

export const updateCart = () => {
  return {
    type: UPDATE_HEADER,
    payload: true
  };
};


export const resetUpdateHeader = () => {
  return {
    type: RESET_UPDATE_HEADER
  };
};

export const fetchCart = () => {
  return {
    type: FETCH_CART,
    payload: true
  };
};


export const resetUpdatePage = () => {
  return {
    type: RESET_UPDATE_PAGE
  };
};

